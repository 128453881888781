body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html * {
  font-size: 14px!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.button.is-small {
  height: 25px;
  font-size: 0.75rem!important;
}

.button .icon.is-small {
  width: 0.75rem!important;
  height: 0.75rem!important;
}
.rthfc.-sp .rthfc-td-fixed {
  z-index: 1!important;
}
.discord-link {
  cursor: pointer;
  position: relative;
  border: none;
  background: none;
  padding: 0;
}
.discord-link:hover:after {
  content: 'copy';
  height: 20px;
  color: black;
  background: white;
  position: absolute;
  top: -10px;
  right: 0;
  border: 1px solid #888;
  padding: 2px;
  line-height: 10px;
}
.discord-link:focus:hover:after {
  content: 'copied';
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-tbody .rt-td {
  overflow: visible;
  white-space: pre-wrap;
}

.ReactTable {
  height: auto !important;
}

.ReactTable .rt-tbody {
  max-height: 75vh;
}

.ReactTable .rt-thead,
.ReactTable .rt-tbody {
  flex: 0 0 auto;
}
/*.ReactTable .rt-thead.-filters {*/
/*  height: 0!important;*/
/*}*/

.ReactTable .rt-thead.-header {
  position: sticky;
  top: 0;
  background: white;
  opacity: 1;
  z-index: 11;
}
.centered {
  justify-content: center!important;
}

main {
  margin: 20px;
}
.overflowed-cell {
  overflow: hidden;
  display: inline-block;
  width: 100%;
  text-overflow: ellipsis;
}

.avatar {
  margin: -7px -5px;
  width: 29px;
  max-width: none;
  border-radius: 50%;
  height: 29px;
  object-fit: cover;
}
